<template>
  <div class="container">
    <h1>Number of Users that Completed all the Cases</h1>
    <div class="filter-container">
      <div class="filters">
        <Filter
          v-for="(filter, index) in filters"
          :key="index"
          v-model:country="filters[index].country"
          v-model:profession="filters[index].profession"
          v-model:group="filters[index].group"
          v-model:user="filters[index].user"
          :countries="countries"
          :professions="professions"
          :groups="groups"
          :users="users"
          @applyFilter="getData"
          @removeFilter="removeDataset(index)"
          :loading="loading"
          :showRemove="filters.length > 1"
        />
      </div>
      <div class="button-container" v-show="!loading">
        <div class="vertical-center">
          <button
            v-show="filters.length < 4"
            @click="addDataset"
            class="btn add-btn"
          />
          <label
            class="add-label"
            v-show="filters.length < 4"
            @click="addDataset"
            >Add Filter</label
          >
        </div>
      </div>
    </div>
    <BarChart
      :labels="labels"
      :datasets="datasets"
      y_axis_title="Number of Users"
    />
  </div>
</template>

<script>
import axios from "axios";
import { getParamString } from "@/assets/js/utility-functions.js";

import BarChart from "@/components/base_charts/BarChart";
import Filter from "@/components/Filter";

export default {
  name: "BarChart3",
  props: ["countries", "professions", "groups", "users"],
  components: {
    BarChart,
    Filter,
  },
  data() {
    return {
      // filter
      filters: [
        {
          country: {name: "All"},
          profession: {name: "All"},
          group: {name: "All"},
          user: {email_address: "All"},
        },
      ],

      // chart data
      labels: [],
      datasets: [
        {
          label: "",
          datasets: {},
        },
      ],

      // flag
      loading: false,

      publicPath: process.env.BASE_URL,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.getData();
    },

    // requests
    getDataFromServer(country_id, profession_id, group_id, user_id) {
      const param_string = getParamString(country_id, profession_id, group_id, user_id);

      return new Promise((resolve) => {
        axios
          .get("get-data-for-bar-chart-3" + param_string)
          .then((res) => {
            resolve(res.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },
    getNumberOfRegisteredUsersFromServer() {
      return new Promise((resolve) => {
        axios
          .get("get-number-of-registered-users")
          .then((res) => {
            resolve(res.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },

    addDataset() {
      this.filters.push({
        country: {name: "All"},
        profession: {name: "All"},
        group: {name: "All"},
        user: {email_address: "All"},
      });
      this.getData();
    },
    removeDataset(index) {
      this.filters.splice(index, 1);
      this.getData();
    },

    // data handling
    getChartData(data, dataset_label) {
      this.labels = [""];
      const dataset = {
        label: dataset_label,
        data: [],
      };
      dataset.data.push(data);
      this.datasets.push(dataset);
    },
    async getData() {
      // make sure there is only one instance running at a time
      if (this.loading === true) {
        window.setTimeout(this.getData, 100);
      } else {
        this.loading = true;
        // get whole data
        this.labels = [""];
        this.datasets = [];

        const response = await this.getNumberOfRegisteredUsersFromServer();
        this.getChartData(response.amount, "Registered Users");

        for (let filter of this.filters) {
          let dataset_name;
          if (filter.user.id) {
            dataset_name = filter.user.email_address;
          } else {
            dataset_name = (filter.country ? filter.country.name : "All") +
              " | " +
              (filter.profession ? filter.profession.name : "All") +
              " | " +
              (filter.group ? filter.group.name : "All");
          }

          const data = await this.getDataFromServer(
            filter.country ? filter.country.id : null,
            filter.profession ? filter.profession.id : null,
            filter.group ? filter.group.id : null,
            filter.user ? filter.user.id : null,
          );
          this.getChartData(data.amount, dataset_name);
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.container {
  margin-block-start: 6rem;
  margin-block-end: 2rem;
}
</style>
