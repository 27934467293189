<template>
  <button
    :style="{
      color: color,
      background: backgroundColor,
      borderColor: borderColor,
      fontSize: `${fontSize}rem`,
    }"
    class="btn"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    color: String,
    backgroundColor: String,
    borderColor: String,
    fontSize: {
      type: Number,
      default: 1,
    },
  },
};
</script>
