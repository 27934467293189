<template>
  <div class="container">
    <div class="input">
      <form>
        <label>Current App Version:</label>
        <div class="input-group">
          <span>v.</span>
          <div v-for="(input, index) in versionInput" :key="index">
            <Input
              :type="!versionEditable ? 'text' : 'number'"
              v-model="versionInput[index]"
              :style="[
                !versionEditable
                  ? `width:${String(versionInput[index]).length}ch !important;`
                  : '',
              ]"
              :disabled="!versionEditable ? true : false"
            />
          </div>
        </div>
      </form>
      <div>
        <Button
          v-show="!versionEditable"
          @click="updateVersion"
          text="update"
          :fontSize="0.7"
          backgroundColor="white"
          borderColor="var(--clr-dark-blue)"
        />
        <Button
          v-show="versionEditable"
          @click="setVersionToServer"
          text="save"
          color="white"
          backgroundColor="var(--clr-dark-blue)"
          :fontSize="0.7"
        />
        <Button
          v-show="versionEditable"
          @click="cancel"
          text="Cancel"
          color="white"
          backgroundColor="var(--clr-red)"
          :fontSize="0.7"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Button from "@/components/Button";
import Input from "@/components/Input";

export default {
  name: "Version",
  components: {
    Button,
    Input,
  },
  data() {
    return {
      versionEditable: false,
      versionInput: undefined,
      initialValue: undefined,
    };
  },
  created() {
    this.init();
  },
  methods: {
    // init
    async init() {
      const res = await this.getVersionFromServer();
      this.initialValue = res;
      this.versionInput = this.initialValue
        .split(".")
        .map((item) => Number(item));
    },

    // requests
    getVersionFromServer() {
      return new Promise((resolve) => {
        axios
          .get("get-app-version")
          .then((res) => {
            resolve(res.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },
    setVersionToServer() {
      return new Promise((resolve) => {
        const data = this.versionInput.join(".");
        axios
          .post("set-app-version", { version: data })
          .then((res) => {
            this.versionEditable = false;
            resolve(res);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },

    // button actions
    updateVersion() {
      this.versionEditable = true;
    },
    cancel() {
      this.versionInput = this.initialValue
        .split(".")
        .map((item) => Number(item));
      this.versionEditable = false;
    },
  },
};
</script>

<style scoped>
form {
  display: flex;
}
label {
  font-weight: 700;
}

input:disabled {
  background: transparent;
  border: none;
}
button + button {
  margin-inline: 0.2rem;
}
.container {
  padding-top: 2em;
}
.input {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.input-group {
  display: flex;
  padding-inline: 0.5em 1em;
}

.input-group > div + div::before {
  content: ".";
}

@media (max-width: 455px) {
  form {
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5em;
  }
  .input {
    flex-direction: column;
  }
  .input-group {
    justify-content: center;
  }
}
</style>
