<template>
  <div class="table-1 container">
    <h1>Table of Users</h1>
    <Filter
      v-model:country="filter.country"
      v-model:profession="filter.profession"
      v-model:group="filter.group"
      v-model:user="filter.user"
      :countries="countries"
      :professions="professions"
      :groups="groups"
      :users="users"
      @applyFilter="updateTable"
      class="filter"
    />
    <div class="table" ref="table" />
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "vue";

// Grid JS Imports
import { Grid, h } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

// Date import
import { format } from 'date-fns'

import { getParamString } from "@/assets/js/utility-functions.js";

import Filter from "@/components/Filter";

export default {
  name: "Table1",
  props: ["countries", "professions", "groups", "users"],
  components: {
    Filter,
  },
  data() {
    return {
      grid: null,
      filter: {
        country: {name: "All"},
        profession: {name: "All"},
        group: {name: "All"},
        user: {email_address: "All"},
      },

      user_objects: [],
    };
  },
  setup() {
    return {
      table: ref("table"),
    };
  },
  created() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        // if page completed do init
        // because render must happen after page load
        this.init();
      }
    };
    if (document.readyState == "complete") {
      this.init();
    }
  },
  methods: {
    async init() {
      this.grid = new Grid();
      const response = await this.getTableDataFromServer(
        this.filter.country ? this.filter.country.id: null,
        this.filter.profession ? this.filter.profession.id : null,
        this.filter.group ? this.filter.group.id : null,
        this.filter.user ? this.filter.user.id : null,
      );
      this.user_objects = this.getTableData(response);
      this.updateConfig();
      this.grid.render(this.table);
    },
    async updateTable() {
      const response = await this.getTableDataFromServer(
        this.filter.country ? this.filter.country.id: null,
        this.filter.profession ? this.filter.profession.id : null,
        this.filter.group ? this.filter.group.id : null,
        this.filter.user ? this.filter.user.id : null,
      );
      this.user_objects = this.getTableData(response);
      this.updateConfig();
      this.renderGrid();
    },
    async toggleBlockStatus(username) {
      const find_user_index = this.user_objects.findIndex((user) => user.user === username);

      if (find_user_index !== -1) {
        await this.blockUserRequest(this.user_objects[find_user_index].id);

        this.user_objects = this.user_objects.map((user) => {
          if (user.user === this.user_objects[find_user_index].user) {
            user.userStatus = !user.userStatus;
          }
          return user;
        });
        this.updateConfig(find_user_index);
        this.renderGrid();
      }
    },

    // Requests
    getTableDataFromServer(country_id, profession_id, group_id, user_id) {
      const param_string = getParamString(country_id, profession_id, group_id, user_id);

      return new Promise((resolve) => {
        axios
          .get("get-data-for-table-1" + param_string)
          .then((res) => {
            resolve(res.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },
    blockUserRequest(user_id) {
      return new Promise((resolve) => {
        axios
          .post("block-user/" + user_id)
          .then((response) => {
            resolve(response.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },

    // data handling
    getTableData(users) {
      return users.map((user) => {
        const return_user = {};
        return_user.id = user.id;
        return_user.user = user.email_address;
        return_user.score = user.points;
        return_user.ranking = user.rank;
        return_user.country = user.country.name;
        return_user.profession = user.profession.name;
        return_user.registrationDate = user.created_at;
        return_user.userStatus = user.blocked;
        return return_user;
      });
    },
    renderGrid() {
      this.grid.forceRender();
    },
    updateConfig(number_of_data_set) {
      this.grid.updateConfig({
        columns: [
          "User",
          "Score",
          "Ranking",
          "Country",
          "Profession",
          {
            name: "Registration Date",
            formatter: (cell, row) => {
              return h(
                "div",
                {},
                format(new Date(row.cells[5].data), "dd MMM yyyy")
              );
            },
            hidden: true,
          },
          {
            name: "User Status",
            formatter: (cell, row) => {
              return h(
                "button",
                {
                  className:
                    (row.cells[6].data ? "blocked" : "unblocked") + " table-btn",
                  onClick: () => this.toggleBlockStatus(row.cells[0].data),
                },
                row.cells[6].data ? "Unblock" : "Block"
              );
            },
          },
        ],
        data: () => this.user_objects,
        pagination: {
          enabled: true,
          limit: 20,
          page: number_of_data_set ? Math.floor(number_of_data_set / 20) : 0,
        },
        search: true,
        sort: true,
      });
    }
  },
};
</script>

<style scoped>
.filter {
  width: 100%;
}

.table-1 {
  margin-block-start: 4rem;
  margin-block-end: 2rem;
}
</style>

<style>
.blocked {
  background-color: var(--clr-light-green);
  border-color: var(--clr-green) !important;
}

.unblocked {
  background-color: var(--clr-light-red);
  border-color: var(--clr-red) !important;
}

td[data-column-id="userStatus"] {
  text-align: center;
}
</style>
