<template>
  <transition name="fade" appear>
    <div class="internal-view" v-if="loggedIn">
      <TheHeader :showLogoutButton="true" @logout="logout" />

      <Version />

      <Table1
        :countries="countries"
        :professions="professions"
        :groups="groups"
        :users="users"
      />
      <BarChart1
        :countries="countries"
        :professions="professions"
        :groups="groups"
        :users="users"
      />
      <BarChart2
        :countries="countries"
        :professions="professions"
        :groups="groups"
        :users="users"
      />
      <BarChart3
        :countries="countries"
        :professions="professions"
        :groups="groups"
        :users="users"
      />
      <LineChart1
        :countries="countries"
        :professions="professions"
        :groups="groups"
        :users="users"
      />
      <BarChart4
        :countries="countries"
        :professions="professions"
        :groups="groups"
        :users="users"
      />
      <LineChart2
        :countries="countries"
        :professions="professions"
        :groups="groups"
        :users="users"
      />
      <RadarChart1
        :countries="countries"
        :professions="professions"
        :groups="groups"
        :users="users"
      />
      <TheFooter />
    </div>
    <div class="login" v-else>
      <Login @successfulLogin="login" />
    </div>
  </transition>
</template>

<script>
import axios from "axios";

import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

import Version from "../components/Version.vue";
import Table1 from "@/components/Table1";
import BarChart1 from "@/components/BarChart1";
import BarChart2 from "@/components/BarChart2";
import BarChart3 from "@/components/BarChart3";
import BarChart4 from "@/components/BarChart4";
import LineChart1 from "@/components/LineChart1";
import LineChart2 from "@/components/LineChart2";
import RadarChart1 from "@/components/RadarChart1";

import Login from "@/components/Login";

export default {
  name: "Home",
  components: {
    TheHeader,
    TheFooter,
    Version,
    Table1,
    BarChart1,
    BarChart2,
    BarChart3,
    BarChart4,
    LineChart1,
    LineChart2,
    RadarChart1,
    Login,
  },
  data() {
    return {
      refreshTokenTimer: null,
      loggedIn: false,

      // values for selects#
      countries: [],
      professions: [],
      groups: [],
      users: [],
    };
  },
  methods: {
    async login() {
      this.loggedIn = true;
      this.countries = await this.getCountriesFromServer();
      this.countries = [{ name: "All" }, ...this.countries];
      this.professions = await this.getProfessionsFromServer();
      this.professions = [{ name: "All" }, ...this.professions];
      this.groups = await this.getGroupsFromServer();
      this.groups = [{ name: "All" }, ...this.groups];
      this.users = await this.getUsersFromServer();
      this.users = [{ email_address: "All" }, ...this.users];
    },
    logout() {
      this.loggedIn = false;
      localStorage.removeItem("token");
      localStorage.removeItem("token_expires");
    },
    // check if the time of expiration is more than 5 min in future
    // if more than 5 min -> do nothing
    // if less than 5 min -> refresh
    refreshToken() {
      if (localStorage.getItem("token")) {
        if (
          new Date().getTime() + 300000 >
          localStorage.getItem("token_expires")
        ) {
          axios
            .get("refresh-token")
            .then((res) => {
              // save token
              localStorage.setItem("token", res.data.access_token);
              localStorage.setItem(
                "token_expires",
                new Date().getTime() + (res.data.expires_in + 1000)
              );
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("token");
              this.login();
            })
            .catch((error) => {
              this.logout();
              console.log(error.response);
            });
        }
      }
    },
    getCountriesFromServer() {
      return new Promise((resolve) => {
        axios
          .get("countries")
          .then((res) => {
            resolve(res.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },
    getProfessionsFromServer() {
      return new Promise((resolve) => {
        axios
          .get("professions")
          .then((res) => {
            resolve(res.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },
    getGroupsFromServer() {
      return new Promise((resolve) => {
        axios
          .get("groups")
          .then((res) => {
            resolve(res.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },
    getUsersFromServer() {
      return new Promise((resolve) => {
        axios
          .get("users")
          .then((res) => {
            resolve(res.data);
          })
          .catch(function(error) {
            console.log(error.response);
          });
      });
    },
  },
  created() {
    // get user if a token is stored in the local storage
    // and if the token is not expired
    // otherwise remove the token with its expiration time
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("token_expires") < new Date().getTime()) {
        this.refreshToken();
      } else {
        this.logout();
      }
    }
    // set a time intervall to automatically refresh token
    // this will check every 5 minutes
    this.refreshTokenTimer = setInterval(this.refreshToken, 300000);
  },
};
</script>
