function getParamString(country_id, profession_id, group_id, user_id) {
  let param_string = '';
  if (country_id || profession_id || group_id || user_id) {
    param_string = `${param_string}?`;
  } else {
    return '';
  }

  if (country_id) {
    param_string = `${param_string}country_id=${country_id}&`;
  }

  if (profession_id) {
    param_string = `${param_string}profession_id=${profession_id}&`;
  }

  if (group_id) {
    param_string = `${param_string}group_id=${group_id}&`;
  }

  if (user_id) {
    param_string = `${param_string}user_id=${user_id}&`;
  }

  return param_string.substring(0, param_string.length-1);
}

function getDateFormat(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let dateNew = date.split("-").reverse();
  return `${dateNew[0]} ${months[Number(dateNew[1])]} ${dateNew[2].substring(
    dateNew[2].length - 2,
    dateNew[2].length
  )}`;
}

function convertToTime(time) {
  let return_string = "";
  if (time > 3600) {
    return_string += Math.floor(time / 3600) + "h ";
    time = time - Math.floor(time / 3600) * 3600;
  }
  if (time > 60) {
    return_string += Math.floor(time / 60) + " min ";
    time = time - Math.floor(time / 60) * 60;
  }
  if (time !== 0) {
    return_string += Math.round(time) + " sec";
  }
  return return_string;
}

export {
  getParamString,
  getDateFormat,
  convertToTime
};
