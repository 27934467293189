<template>
  <form class="filter">
    <div>
      <div class="form">
        <div class="input-group">
          <label for="country-filter">Country</label>
          <v-select
            class="select"
            name="country-filter"
            :modelValue="country"
            @option:selected="setCountry"
            label="name"
            :options="countries"
            :clearable="false"
            :disabled="onlyShowUserFilter"
          />
        </div>
        <div class="input-group">
          <label for="profession-filter">Profession</label>
          <v-select
            class="select"
            name="profession-filter"
            :modelValue="profession"
            @option:selected="setProfession"
            label="name"
            :options="professions"
            :clearable="false"
            :disabled="onlyShowUserFilter"
          />
        </div>
        <div class="input-group">
          <label for="group-filter">Group</label>
          <v-select
            class="select"
            name="group-filter"
            :modelValue="group"
            @option:selected="setGroup"
            label="name"
            :options="groups"
            :clearable="false"
            :disabled="onlyShowUserFilter"
          />
        </div>
        <div class="input-group">
          <label for="user-filter">User</label>
          <v-select
            class="select"
            name="user-filter"
            :modelValue="user"
            @option:selected="setUser"
            label="email_address"
            :options="filtered_users"
            :clearable="false"
          />
        </div>
      </div>
      <div v-show="showRemove && loaded && !loading" class="button-container">
        <div class="vertical-center">
          <button
            @click="removeFilter"
            class="btn sub-btn vertical-center"
            type="button"
            :disabled="loading"
          />
          <label class="sub-label" @click="removeFilter">Remove Filter</label>
        </div>
      </div>
      <div v-show="showReset && loaded && !loading" class="button-container">
        <div class="vertical-center">
          <button
            @click="resetFilter"
            class="btn res-btn vertical-center"
            type="button"
            :disabled="loading"
          />
          <label class="sub-label" @click="resetFilter">Reset Filter</label>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import "vue-select/dist/vue-select.css";

export default {
  name: "Filter",
  props: [
    "country",
    "profession",
    "group",
    "user",
    "countries",
    "professions",
    "groups",
    "users",
    "showRemove",
    "loading",
  ],
  data() {
    return {
      // loading
      loaded: false,

      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    onlyShowUserFilter() {
      return (
        this.user &&
        JSON.stringify(this.user) !== JSON.stringify({ email_address: "All" })
      );
    },
    showReset() {
      if (
        this.country.id ||
        this.profession.id ||
        this.group.id ||
        this.user.id
      ) {
        return true;
      } else {
        return false;
      }
    },
    filtered_users() {
      if (this.country.id || this.profession.id || this.group.id) {
        let filtered_users = JSON.parse(JSON.stringify(this.users));
        // remove the "all" object
        filtered_users.splice(0, 1);
        if (this.country.id) {
          filtered_users = filtered_users.filter(
            (user) => user.country_id === this.country.id
          );
        }
        if (this.profession.id) {
          filtered_users = filtered_users.filter(
            (user) => user.profession_id === this.profession.id
          );
        }
        if (this.group.id) {
          filtered_users = filtered_users.filter((user) => {
            const found = user.groups.find(
              (group) => group.id === this.group.id
            );
            if (found) {
              return true;
            } else {
              return false;
            }
          });
        }
        // add the all object back
        filtered_users = [{ email_address: "All" }, ...filtered_users];
        return filtered_users;
      } else {
        return this.users;
      }
    },
  },
  created() {
    this.loaded = true;
  },
  methods: {
    // button actions
    removeFilter() {
      this.$emit("removeFilter");
    },
    resetFilter() {
      this.$emit("update:country", { name: "All" });
      this.$emit("update:profession", { name: "All" });
      this.$emit("update:group", { name: "All" });
      this.$emit("update:user", { email_address: "All" });
      this.$emit("applyFilter");
    },

    // handlers for setting options
    setCountry(country) {
      this.$emit("update:country", country);
      this.$emit("applyFilter");
    },
    setProfession(profession) {
      this.$emit("update:profession", profession);
      this.$emit("applyFilter");
    },
    setGroup(group) {
      this.$emit("update:group", group);
      this.$emit("applyFilter");
    },
    setUser(user) {
      this.$emit("update:user", user);
      this.$emit("applyFilter");
    },
  },
  emits: [
    "applyFilter",
    "update:country",
    "update:profession",
    "update:group",
    "update:user",
    "removeFilter",
  ],
};
</script>

<style scoped>
.form {
  display: inline-block;
  font-size: clamp(1rem, 0.6rem + 1vw, 1.2rem);
}

.form label {
  margin-right: 1rem;
}

.filter {
  text-align: left;
}

.input-group {
  display: inline-block;

  width: 10rem;

  margin-right: var(--filter-gap);
}

.select {
  background: white;
  border-radius: 4px;

  max-height: 38px;
}

@media (max-width: 915px) {
  .filter {
    padding: 0.7rem;
    border-radius: 4px;
    background: rgb(237, 237, 237);
  }

  .input-group {
    margin-top: 0.5rem;
  }
}

/* media queries */
@media (max-width: 455px) {
  .container {
    padding-inline: 1rem;
  }

  .input-group {
    width: 100%;
  }
}

@media (min-width: 375px) {
  .button-container + .button-container {
    margin-left: 1rem;
  }
}

@media (max-width: 375px) {
  .button-container {
    display: block;
    text-align: left;
  }

  .vertical-center {
    justify-content: flex-start;
  }
}
</style>

<style>
.vs__dropdown-toggle {
  max-height: 38px;
  overflow-y: auto;
}

.vs__dropdown-menu {
  width: 300px;
}
</style>
