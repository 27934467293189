<template>
  <header>
    <div class="container">
      <div class="logo">
        <img :src="`${publicPath}images/logo-white-wide.png`" />
      </div>
      <div class="logout">
        <Button
          v-show="showLogoutButton"
          text="Logout"
          color="white"
          borderColor="white"
          backgroundColor="transparent"
          @click="logout"
        />
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";

import Button from "@/components/Button";

export default {
  name: "TheHeader",
  components: {
    Button,
  },
  props: {
    showLogoutButton: Boolean,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    logout() {
      axios.post("/logout").catch((error) => {
        console.log(error);
      });
      this.$emit("logout");
    },
  },
  emits: ["logout"],
};
</script>

<style scoped>
header {
  padding-block: 1rem;
  background-color: var(--clr-dark-blue);
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  max-height: 4rem;
}

.logout {
  align-self: center;
}

/* tablet styles */
@media (max-width: 760px) {
  .logo img {
    max-height: 2em;
    margin: auto;
  }
}
</style>
