<template>
    <TheHeader :showLogoutButton="false" />
    <div class="main">
        <div class="success-message container" v-if="success">
            <h1>Password successfully reset!</h1>
        </div>
        <div class="failure-message container" v-else-if="failure">
            <h1>Failed to reset password!</h1>
            <p>The link has expired! Please use the forgot password function again.</p>
        </div>
        <div class="form" v-else>
            <div>
                <form @submit.prevent="submit">
                    <input type="email" v-model="email" placeholder="Email" required>
                    <input type="password" v-model="password" placeholder="Password" required>
                    <input type="password" v-model="password_confirmation" placeholder="Confirm Password" required>
                    <button class="btn submit-btn" type="submit">Reset Password</button>
                </form>
            </div>
        </div>
    </div>
    <TheFooter />
</template>

<script>
import TheHeader from '@/components/TheHeader';
import TheFooter from '@/components/TheFooter';
import axios from 'axios';

export default {
    name: "ResetPassword",
    components: {
        TheHeader,
        TheFooter
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,

            // input variables
            email: '',
            password: '',
            password_confirmation: '',

            // user notifications
            notifications: [],

            // flags
            success: false,
            failure: false,

            // fail message
            fail_message: '',
        }
    },
    computed: {
        token: function() {
            return this.$route.params.token;
        }
    },
    methods: {

        // requests
        updatePasswordRequest(password, password_confirmation, token, email_address) {
            const data = {
                password: password,
                password_confirmation: password_confirmation,
                token: token,
                email_address: email_address
            };
            return new Promise((resolve, reject) => {
                axios.post('/update-password', data)
                .then(res => {
                    resolve(res.data);
                })
                .catch(error => {
                    console.log(error.response);
                    reject(error.response);
                });
            })
        },
        async submit() {
            const response = await this.updatePasswordRequest(this.password, this.password_confirmation, this.token, this.email);
            if (response.status === 'success') {
                this.success = true;
            } else {
                this.failure = true;
                this.fail_message = response.message;
            }
        }
    }
}
</script>

<style scoped>

input {
    background-color: transparent;
    border: none;
    border-bottom: 2px var(--clr-dark-blue) solid;
    color: var(--clr-dark-blue);
    display: block;
    margin-inline: auto;
    width: max(240px, 60%);
    line-height: 1.5em;
    font-size: clamp(0.9rem, 0.6rem + 2vw, 1.5rem);
    margin-bottom: 1rem;
}

input::placeholder {
    color: var(--clr-dark-blue);
}

input:focus-visible {
    outline: none;
}

.form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    height: 70%;
    
    text-align: center;
    padding: 2rem;

    display: flex;
    align-items: center;

    margin: auto;
}

.form > div {
    width: clamp(310px, 40%, 600px);
    margin: auto;
}

.form p {
    position: absolute;
    bottom: 0;
}

.main {
    position: relative;
    flex: 1;
}

.submit-btn {
    margin-top: 1.5rem;
    font-size: clamp(0.9rem, 0.6rem + 2vw, 1.3rem);
    font-weight: 700;
    background-color: var(--clr-dark-blue);
    border-color: var(--clr-dark-blue);
    color: #fff;
    padding: 0.5rem clamp(1.2rem, 0.8rem + 2vw, 2rem);
}

</style>