<template>
  <ChartJSRadarChart v-bind="radarChartProps" />
</template>

<script>
import { ref, computed } from "vue";
import { RadarChart as ChartJSRadarChart, useRadarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default {
  name: "RadarChart",
  props: {
    labels: Array,
    datasets: Array,
  },
  data() {
    return {
      backgroundColor: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
      ],
      borderColor: [
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
      ],
      displayed_datasets: [],
      displayed_labels: [],
    };
  },
  components: {
    ChartJSRadarChart,
  },
  setup() {
    const chart_datasets = ref();
    const chart_labels = ref();

    const data = computed(() => ({
      labels: chart_labels.value,
      datasets: chart_datasets.value,
    }));

    const options = computed(() => ({
      scales: {
        r: {
          pointLabels: {
            font: {
              size: 16,
            }
          },
          beginAtZero: true,
          suggestedMax: 1,
        },
      },
    }));

    const { radarChartProps, radarChartRef } = useRadarChart({
      chartData: data,
      options,
    });

    function writeChartData(datasets, labels) {
      chart_datasets.value = datasets;
      chart_labels.value = labels;
    }

    return {
      writeChartData,
      radarChartProps,
      radarChartRef,
    };
  },
  created() {
    this.updateChartData();
  },
  methods: {
    updateChartData() {
      this.displayed_datasets = this.datasets.map((item, index) => {
        item.borderColor = this.borderColor[index];
        item.borderWidth = 1;
        item.backgroundColor = this.backgroundColor[index];
        return item;
      });
      this.displayed_labels = this.labels;

      this.writeChartData(this.displayed_datasets, this.displayed_labels);
    },
  },
  watch: {
    labels: "updateChartData",
    datasets: "updateChartData",
  },
};
</script>
