<template>
  <ChartJSLineChart v-bind="lineChartProps" />
</template>

<script>
import { ref, computed } from "vue";
import { LineChart as ChartJSLineChart, useLineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-date-fns";
Chart.register(...registerables);

export default {
  name: "LineChart",
  props: {
    labels: Array,
    datasets: Array,
    x_axis_title: String,
    y_axis_title: String,
    type: String,
  },
  data() {
    return {
      backgroundColor: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
      ],
      borderColor: [
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
      ],
      displayed_datasets: [],
      displayed_labels: [],
    };
  },
  components: {
    ChartJSLineChart,
  },
  setup() {
    const chart_datasets = ref();
    const chart_labels = ref();
    const chart_x_axis_title = ref();
    const chart_y_axis_title = ref();
    const chart_type = ref();

    const data = computed(() => ({
      labels: chart_labels.value,
      datasets: chart_datasets.value,
    }));

    const options = computed(() => ({
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: chart_y_axis_title.value,
          },
        },
        x: {
          type: chart_type.value,
          time: {
            tooltipFormat: "dd MMM yy",
            unit: "day",
            displayFormats: {
              day: "dd MMM yy",
            },
          },
          title: {
            display: true,
            text: chart_x_axis_title.value,
          },
          maxBarThickness: 0.4,
        },
      },
    }));

    const { lineChartProps, lineChartRef } = useLineChart({
      chartData: data,
      options,
    });

    function writeChartData(datasets, labels, x_axis_title, y_axis_title, type) {
      chart_datasets.value = datasets;
      chart_labels.value = labels;
      chart_x_axis_title.value = x_axis_title;
      chart_y_axis_title.value = y_axis_title;
      chart_type.value = type;
    }

    return {
      writeChartData,
      lineChartProps,
      lineChartRef,
    };
  },
  created() {
    this.updateChartData();
  },
  methods: {
    updateChartData() {
      this.displayed_datasets = this.datasets.map((item, index) => {
        item.borderColor = this.borderColor[index];
        item.borderWidth = 1;
        item.backgroundColor = this.backgroundColor[index];
        return item;
      });
      this.displayed_labels = this.labels;

      this.writeChartData(
        this.displayed_datasets,
        this.displayed_labels,
        this.x_axis_title,
        this.y_axis_title,
        this.type
      );
    },
  },
  watch: {
    labels: "updateChartData",
    datasets: {
      handler: "updateChartData",
      deep: true,
    },
    x_axis_title: "updateChartData",
    y_axis_title: "updateChartData",
  },
};
</script>
