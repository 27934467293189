<template>
    <TheHeader :showLogoutButton="false" />
    <div class="main container">
        <h1>Page Not Found</h1>
    </div>
    <TheFooter />
</template>

<script>
import TheHeader from '@/components/TheHeader';
import TheFooter from '@/components/TheFooter';

export default {
    name: "ForgotPassword",
    components: {
        TheHeader,
        TheFooter
    },
}
</script>

<style>

.main {
    padding-top: 4rem;
    flex: 1;
}
</style>