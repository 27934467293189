<template>
  <div class="login">
    <div class="container">
      <img :src="`${publicPath}images/logo-blue.png`" />
      <form @submit.prevent="login">
        <input type="email" v-model="email" placeholder="Email" required />
        <input
          type="password"
          v-model="password"
          placeholder="Password"
          required
        />
        <button class="btn login-btn" type="submit">Login</button>
      </form>
      <p v-show="client_error">Wrong Email or Password used!</p>
      <p v-show="server_error">Server is not available!</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      publicPath: process.env.BASE_URL,

      // input variables
      email: "",
      password: "",

      // user notifications
      client_error: false,
      server_error: false,
    };
  },
  methods: {
    login() {
      axios
        .post("admin-login", {
          email_address: this.email,
          password: this.password,
        })
        .then((res) => {
          // save token
          localStorage.setItem("token", res.data.access_token);
          localStorage.setItem(
            "token_expires",
            new Date().getTime() + (res.data.expires_in + 1000)
          );
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + localStorage.getItem("token");
          this.$emit("successfulLogin");
        })
        .catch((error) => {
          // reset notifications
          this.notifications = [];

          // check if the error has a response
          if (error.response) {
            // check if thee response status is 422
            if (error.response.status === 422) {
              this.client_error = true;
            }
            // check if thee response status is 401
            if (error.response.status === 401) {
              this.client_error = true;
            }
            // check if thee response status is 500
            if (error.response.status === 500) {
              this.server_error = true;
              console.log(error.response);
            }
          }
        });
    },
  },
  emits: ["successfulLogin"],
};
</script>

<style scoped>
.login {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login .container {
  border: solid 1px var(--clr-dark-blue);
  border-radius: 1rem;
  padding: 40px 90px;
}
.login img {
  max-width: 100%;
  width: clamp(10rem, 8rem + 5vw, 20rem);
  margin-bottom: 1rem;
}
input {
  background-color: transparent;
  border: none;
  border-bottom: 2px var(--clr-dark-blue) solid;
  color: var(--clr-dark-blue);
  display: block;
  line-height: 1.5em;
  font-size: clamp(0.9rem, 0.6rem + 2vw, 1.5rem);
  margin-bottom: 1rem;
}
input::placeholder {
  color: var(--clr-dark-blue);
}
input:focus-visible {
  outline: none;
}
.login-btn {
  margin: 1.5rem;
  font-size: clamp(0.9rem, 0.6rem + 2vw, 1.3rem);
  font-weight: 700;
  background-color: var(--clr-dark-blue);
  border-color: var(--clr-dark-blue);
  color: #fff;
  padding: 0.5rem clamp(1.2rem, 0.8rem + 2vw, 2rem);
}
.login-btn:hover {
  border-color: var(--clr-dark-blue);
  background-color: #fff;
  color: var(--clr-dark-blue);
}
p {
  position: absolute;
  bottom: 0;
}
</style>
