<template>
  <input
    :type="type"
    :value="modelValue"
    @input="update"
    :disabled="disabled"
  />
</template>

<script>
export default {
  name: "Input",
  props: {
    type: String,
    modelValue: Number,
    disabled: Boolean,
  },
  methods: {
    update(event) {
      this.$emit("update:modelValue", Number(event.target.value));
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style scoped>
input {
  width: 3.2em;
}
</style>
