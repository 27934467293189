import axios from "axios";

// DEV
// axios.defaults.baseURL = "http://crash-savers.webbees-test.eu/api/";

// PROD
axios.defaults.baseURL = "https://crashsaversvr.com/api/";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
