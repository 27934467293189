import { createApp } from 'vue'
import App from './App.vue'
import './axios'
import './assets/css/styles.css'
import router from './router'
import vSelect from 'vue-select'

const app = createApp(App).use(router);

app.component('v-select', vSelect);

app.mount('#app');
