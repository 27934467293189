<template>
     <footer>
        <div class="footer">
        </div>
    </footer>   
</template>


<style>
.footer {
    margin-top: 8rem;
    padding: 1rem;
    background-color: var(--clr-dark-blue);
    min-height: 3rem;
}
</style>