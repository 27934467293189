<template>
  <ChartJSBarChart v-bind="barChartProps" />
</template>

<script>
import { ref, computed } from "vue";
import { BarChart as ChartJSBarChart, useBarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

import { convertToTime } from "@/assets/js/utility-functions.js"

Chart.register(...registerables);

export default {
  name: "BarChart",
  props: {
    labels: Array,
    datasets: Array,
    tooltips: Array,
    x_axis_title: String,
    y_axis_title: String,
    unit: String,
    display_max_value: {
      type: Number,
      default: undefined,
    }
  },
  data() {
    return {
      backgroundColor: [
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
      ],
      borderColor: [
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
      ],
      displayed_datasets: [],
      displayed_labels: [],
    };
  },
  components: {
    ChartJSBarChart,
  },
  setup() {
    const chart_datasets = ref();
    const chart_labels = ref();
    const chart_tooltips = ref();
    const chart_x_axis_title = ref();
    const chart_y_axis_title = ref();
    const chart_unit = ref();
    const chart_display_max_value = ref();

    const data = computed(() => ({
      labels: chart_labels.value,
      datasets: chart_datasets.value,
    }));

    const tooltips = computed(() => ({
      tooltips: chart_tooltips.value,
    }));

    const options = computed(() => ({
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: chart_y_axis_title.value,
          },
          suggestedMax: chart_display_max_value.value,
          ticks: {
            callback: function(value) {
              if (chart_unit.value === "time") {
                return convertToTime(value);
              } else if (chart_unit.value === "percentage") {
                return value + "%";
              } else {
                return value;
              }
            },
          },
        },
        x: {
          title: {
            display: true,
            text: chart_x_axis_title.value,
          },
          maxBarThickness: 0.4,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              let label = tooltipItem.dataset.label || "";

              if (chart_tooltips.value) {
                return (label = `${label}: ${chart_tooltips.value[tooltipItem.datasetIndex][tooltipItem.dataIndex]}`);
              } else {
                if (chart_unit.value === "time") {
                  const value = tooltipItem.raw;
                  if (!(value === null || value === undefined)) {
                    return `${label}: ${convertToTime(value)}`;
                  }
                } else {
                  const value = tooltipItem.formattedValue;
                  if (!(value === null || value === undefined)) {
                    if (chart_unit.value === "percentage") {
                      return `${label}: ${value}%`;
                    } else {
                      return `${label}: ${value}`;
                    }
                  }
                }
              }              
            },
          },
        },
      },
    }));

    const { barChartProps, barChartRef } = useBarChart({
      chartData: data,
      options,
      tooltips,
    });

    function writeChartData(
      datasets,
      labels,
      tooltips,
      x_axis_title,
      y_axis_title,
      unit,
      display_max_value,
    ) {
      chart_datasets.value = datasets;
      chart_labels.value = labels;
      chart_tooltips.value = tooltips;
      chart_x_axis_title.value = x_axis_title;
      chart_y_axis_title.value = y_axis_title;
      chart_unit.value = unit;
      chart_display_max_value.value = display_max_value;
    }

    return {
      writeChartData,
      barChartProps,
      barChartRef,
    };
  },
  created() {
    this.updateChartData();
  },
  methods: {
    updateChartData() {
      this.displayed_datasets = this.datasets.map((item, index) => {
        item.borderColor = this.borderColor[index];
        item.borderWidth = 1;
        item.backgroundColor = this.backgroundColor[index];
        return item;
      });
      this.displayed_labels = this.labels;

      this.writeChartData(
        this.displayed_datasets,
        this.displayed_labels,
        this.tooltips,
        this.x_axis_title,
        this.y_axis_title,
        this.unit,
        this.display_max_value,
      );
    },
  },
  watch: {
    labels: "updateChartData",
    datasets: "updateChartData",
    tooltips: "updateChartData",
    x_axis_title: "updateChartData",
    y_axis_title: "updateChartData",
    unit: "updateChartData",
    display_max_value: "updateChartData",
  },
};
</script>
